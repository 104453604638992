import React from "react";
import { Container } from "reactstrap";

import ContactButton from "components/ContactButton";
import Section from "components/Section";

const Footer = () => (
  <footer className="text-center">
    <Section id="get-in-touch" padding={Section.PADDING_SMALL}>
      <Container>
        <h2>Want to get in touch?</h2>
        <ContactButton className="mt-4" size="lg" />
      </Container>
    </Section>
    <Section gray padding={Section.PADDING_TINY}>
      <strong>Daniel Emeralde &copy; {new Date().getFullYear()}</strong>
    </Section>
  </footer>
);

export default Footer;
