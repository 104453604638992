import { graphql, StaticQuery } from "gatsby";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "reactstrap";

import Footer from "components/Footer";
import HeadingSection from "components/HeadingSection";
import { default as Layout } from "components/Layout";
// eslint-disable-next-line
import MainHeader from "components/MainHeader";
import Navbar from "components/Navbar";
import PortfolioItem from "components/PortfolioItem";
import HTML from "components/HTML";
import Reference from "components/Reference";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import SkillList from "components/SkillList";
import SocialLink from "components/SocialLink";

export const IndexPage = ({ data, scrollTop, toggleSidebar }) => (
  <div>
    <Navbar solid={scrollTop > 0} toggleSidebar={toggleSidebar} />
    <main>
      <div id="intro">
        <Section bgStars>
          <Section roundedBottom padding={Section.PADDING_LARGE}>
            <Container>
              <MainHeader
                title="Daniel Emeralde"
                subtitle={get(data, "site.siteMetadata.subtitle")}
              >
                <SocialLink
                  type={SocialLink.TYPES.BLOG}
                  to="https://daniel-blog.emeralde.me"
                />
                <SocialLink
                  type={SocialLink.TYPES.GITHUB}
                  to="https://github.com/demeralde"
                />
                <SocialLink
                  type={SocialLink.TYPES.STACKOVERFLOW}
                  to="https://stackoverflow.com/users/4260047/daniel"
                />
                {/* <SocialLink
                  type={SocialLink.TYPES.LINKEDIN}
                  to="https://www.linkedin.com/in/daniel-dot-tech/"
                /> */}
              </MainHeader>
            </Container>
          </Section>
        </Section>
        <Section roundedTop padding={Section.PADDING_SMALL}>
          <Container>
            <Row>
              <Col className="text-center" lg={{ size: 8, offset: 2 }}>
                <HTML>{data.introMarkdown.html}</HTML>
              </Col>
            </Row>
          </Container>
        </Section>
      </div>
      <Section id="skillset" gray padding={Section.PADDING_SMALL}>
        <Container>
          <SectionHeader
            index={1}
            title="Skillset"
            description="As an individual with diverse interests and experience,
              I've developed a broad, yet deep skillset as a developer."
          />
          <Row>
            <Col lg="6">
              <HeadingSection
                icon="developer"
                title="Developer"
                size={3}
                noMargin
              >
                <HTML>{data.developerSkillsetMarkdown.html}</HTML>
              </HeadingSection>
            </Col>
            <Col lg="6">
              <HeadingSection
                icon="react"
                title="Front-end development"
                size={5}
                className="mt-lg-0"
              >
                <SkillList
                  skills={[
                    "React.js",
                    "TypeScript",
                    "JavaScript",
                    "Next.js",
                    "Gatsby.js",
                    "Tailwind CSS",
                    "Styled Components",
                    "HTML/CSS/SCSS",
                    "Apollo",
                    "Redux",
                    "React Native",
                    "Reanimated",
                    "Expo",
                    "Jest",
                    "React Testing Library",
                  ]}
                />
              </HeadingSection>
              <HeadingSection
                icon="python"
                title="Back-end development"
                size={5}
              >
                <SkillList
                  skills={[
                    "Python",
                    "Django",
                    "Go",
                    "PostgreSQL",
                    "GraphQL",
                    "REST",
                    "tRPC",
                    "Node.js",
                    "CockroachDB",
                    "Redis",
                    "RabbitMQ",
                  ]}
                />
              </HeadingSection>
              <HeadingSection icon="linux" title="DevOps" size={5}>
                <SkillList
                  skills={[
                    "Linux",
                    "Docker",
                    "VPS (DigitalOcean, Linode)",
                    "Netlify",
                    "Sentry",
                    "CMS (Ghost)",
                    "AWS Certified Cloud Practitioner",
                  ]}
                />
              </HeadingSection>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <HeadingSection icon="design" title="Design sense" size={3}>
                <HTML>{data.designSkillsetMarkdown.html}</HTML>
              </HeadingSection>
            </Col>
            <Col md="6">
              <HeadingSection icon="business" title="Business insight" size={3}>
                <HTML>{data.businessSkillsetMarkdown.html}</HTML>
              </HeadingSection>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section id="values" bgStars padding={Section.PADDING_SMALL}>
        <Container>
          <SectionHeader
            index={2}
            title="Values"
            description="In addition to the technical, design, and business skills required to produce world-class software, I'm also a well-rounded and valuable team player."
          />
          <Row>
            <Col lg="3" sm="6">
              <HeadingSection title="Passion" icon="passion" size={4} noMargin>
                As a self-taught engineer since 17 years old, I love what I do.
                This enthusiasm ensures I consistently hold my work to the
                highest standard.
              </HeadingSection>
            </Col>
            <Col lg="3" sm="6">
              <HeadingSection
                title="Craftsmanship"
                icon="craftsmanship"
                size={4}
                className="mt-sm-0"
              >
                I&apos;m creative, meticulous, take my time, and enjoy building
                software properly. My holistic approach ensures I treat all
                tasks equally.
              </HeadingSection>
            </Col>
            <Col lg="3" sm="6">
              <HeadingSection
                title="Communication"
                icon="easy-going"
                size={4}
                className="mt-lg-0"
              >
                I&apos;m kind and have excellent communication skills, which
                makes working together easy. I respect others&apos; time and
                feedback.
              </HeadingSection>
            </Col>
            <Col lg="3" sm="6">
              <HeadingSection
                title="Leadership"
                icon="communication"
                size={4}
                className="mt-lg-0"
              >
                I&apos;m a confident decision maker who takes responsibility for
                a project&apos;s success, both as a leader or team member.
              </HeadingSection>
            </Col>
            <Col lg="3" sm="6">
              <HeadingSection title="Focus" icon="focus" size={4}>
                I have an innate and refined concentration ability, which
                enables me to efficiently complete all tasks to an excellent
                standard.
              </HeadingSection>
            </Col>
            <Col lg="3" sm="6">
              <HeadingSection title="Curiosity" icon="curiosity" size={4}>
                I love learning and dedicate myself to my craft. I always seek
                growth over comfort in the pursuit of mastery.
              </HeadingSection>
            </Col>
            <Col lg="3" sm="6">
              <HeadingSection
                title="Self-motivation"
                icon="independence"
                size={4}
              >
                I take initiative and persevere through challenges until
                completion. You can count on me to steer your project towards
                success.
              </HeadingSection>
            </Col>
            <Col lg="3" sm="6">
              <HeadingSection
                title="Innovation"
                icon="open-mindedness"
                size={4}
              >
                I&apos;m an original thinker who is comfortable with change. I
                desire expansion and never hesitate to grow beyond my
                preconceptions.
              </HeadingSection>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section id="showcase" padding={Section.PADDING_SMALL}>
        <Container>
          <SectionHeader
            index={3}
            title="Showcase"
            description="In addition to behind-the-scenes proprietary work for
              clients, here's a few of the many projects I've worked on over the years."
          />
          {data.portfolioItems.edges.map(({ node }, index) => (
            <PortfolioItem
              noMargin={index === 0}
              key={node.name}
              alt={index % 2 > 0}
              name={node.name}
              description={node.description}
              year={node.year}
              siteLink={node.siteLink}
              skillsUsed={node.skillsUsed}
              sourceCode={node.sourceCode}
              caseStudy={node.caseStudy}
              imageSizes={node.image.childImageSharp.fluid}
            />
          ))}
        </Container>
      </Section>
      <Section id="my-story" gray padding={Section.PADDING_SMALL}>
        <Container>
          <SectionHeader
            index={4}
            title="My story"
            description="Learn more about who I am and why I love what I do."
          />
          <Row>
            <Col lg="7">
              <div className="mb-4">
                <HeadingSection
                  size={4}
                  icon="git-branch"
                  title="My journey as a developer"
                  noMargin
                >
                  <HTML>{data.aboutMeDeveloperJourneyMarkdown.html}</HTML>
                </HeadingSection>
              </div>
              <HeadingSection size={4} icon="about-me" title="More about me">
                <HTML>{data.aboutMeMainMarkdown.html}</HTML>
              </HeadingSection>
            </Col>
            <Col lg="5">
              <HeadingSection size={4} icon="quote" title="Favourite quotes">
                <HTML className="quote">{data.aboutMeQuotesMarkdown.html}</HTML>
              </HeadingSection>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section bgStars padding={Section.PADDING_SMALL}>
        <Container>
          <SectionHeader
            index={5}
            title="References"
            description="Don't take my word for it. Here's what people I've
              worked with have said about me."
          />
          <Row>
            {data.references.edges.map(
              ({ node: { html, frontmatter } }, index) => (
                <Col xs="12" key={frontmatter.id}>
                  <Reference
                    noMargin={index === 0}
                    authorName={frontmatter.authorName}
                    authorPosition={frontmatter.authorPosition}
                    authorAvatar={
                      frontmatter.authorAvatar.childImageSharp.fixed
                    }
                  >
                    {html}
                  </Reference>
                </Col>
              )
            )}
          </Row>
        </Container>
      </Section>
    </main>
    <Footer />
  </div>
);

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  scrollTop: PropTypes.number.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

const IndexPageContainer = props => (
  <Layout
    render={({ scrollTop, toggleSidebar }) => (
      <StaticQuery
        query={graphql`
          query IndexQuery {
            ...PortfolioItems
            ...References
            site {
              siteMetadata {
                subtitle
              }
            }
            aboutMeDeveloperJourneyMarkdown: markdownRemark(
              frontmatter: { id: { eq: "content-about-me-developer" } }
            ) {
              html
            }
            aboutMeExtraMarkdown: markdownRemark(
              frontmatter: { id: { eq: "content-about-me-extra" } }
            ) {
              html
            }
            aboutMeMainMarkdown: markdownRemark(
              frontmatter: { id: { eq: "content-about-me-main" } }
            ) {
              html
            }
            aboutMeQuotesMarkdown: markdownRemark(
              frontmatter: { id: { eq: "content-about-me-quotes" } }
            ) {
              html
            }
            introMarkdown: markdownRemark(
              frontmatter: { id: { eq: "content-intro" } }
            ) {
              html
            }
            businessSkillsetMarkdown: markdownRemark(
              frontmatter: { id: { eq: "content-skillset-business" } }
            ) {
              html
            }
            designSkillsetMarkdown: markdownRemark(
              frontmatter: { id: { eq: "content-skillset-design" } }
            ) {
              html
            }
            developerSkillsetMarkdown: markdownRemark(
              frontmatter: { id: { eq: "content-skillset-developer" } }
            ) {
              html
            }
          }
        `}
        render={data => (
          <IndexPage
            data={data}
            scrollTop={scrollTop}
            toggleSidebar={toggleSidebar}
            {...props}
          />
        )}
      />
    )}
  />
);

export default IndexPageContainer;
