import PropTypes from "prop-types";
import React from "react";

import Icon from "components/Icon";
import "./scss/SocialLink.scss";

const TYPES = {
  BLOG: {
    title: "Blog",
    icon: "blog",
  },
  GITHUB: {
    title: "GitHub",
    icon: "github",
  },
  LINKEDIN: {
    title: "LinkedIn",
    icon: "linkedin",
  },
  STACKOVERFLOW: {
    title: "StackOverflow",
    icon: "stack-overflow",
  },
};

const SocialLink = ({ type, to }) => (
  <a
    className="SocialLink"
    href={to}
    target="_blank"
    rel="noopener noreferrer"
    title={type.title}
  >
    <Icon name={type.icon} />
  </a>
);

SocialLink.propTypes = {
  type: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  to: PropTypes.string.isRequired,
};

SocialLink.TYPES = TYPES;

export default SocialLink;
